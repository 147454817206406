import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DEFINITIONS } from '../definitions';
import { ParameterService } from './parameter.service';
import { ModuleData } from './module-data';

   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts
   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts
// import allTestParts from './../_DEBUG/allParts.DC-AE.json';
   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts
   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts

@Injectable({
  providedIn: 'root'
})

export class ModuleDataService {

  // data of selected module
  private moduleDataSelected: ModuleData;

  // observables
  private bSubjectServiceIsReady = new BehaviorSubject(false);
  private serviceIsReady = false;

  // config-api: all available organisations and parts
  private allParts = new Map();
  private readonly urlGeneratorApiOrgs = DEFINITIONS.urlGeneratorApi + '/organisations';

  constructor(private logger: NGXLogger,
              private http: HttpClient,
              private parService: ParameterService) {
    this.logger.info('(module-data.service) => constructor()');
    this.initModuleData();

   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts
   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts
// this.allParts.set('DC-AE', allTestParts);
   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts
   // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Local DEV environment: Load parts
  }

  public get ModuleDataLoaded(): boolean {
    return (this.moduleDataSelected !== undefined);
  }

  //
  // Read part data from config api (ecad.generator.server)
  //
  private initModuleData() {
    // get all orgs
    this.http.get(this.urlGeneratorApiOrgs).subscribe(

      (orgs: Array<any>) => {
        // check for orgs
        this.logger.info('(module-data.service) Organisations in DB => ' + JSON.stringify(orgs));
        if (orgs.length === 0) {
          // service is ready
          this.logger.info('(module-data.service) No Organisations found! - service is ready!');
          this.serviceIsReady = true;
          this.bSubjectServiceIsReady.next(true);
          return;
        }

        // get all urls to query parts
        const urlsAllParts: Array<string> = new Array();
        orgs.forEach((organisation) => {
          const urlPartsOfOrg = DEFINITIONS.urlGeneratorApi + '/organisations/' + organisation.orgid + '/parts';
          urlsAllParts.push(urlPartsOfOrg);
          this.logger.info('(module-data.service) Organisation Url => ' + urlPartsOfOrg);
        });

        // get all parts of all orgs
        // const allPartsObservables = urlsAllParts.map(url => this.http.get<object>(url).catch(() => of(null)));
        const allPartsObservables = urlsAllParts.map(url => this.http.get<object[]>(url).pipe(catchError((err) => of(err))));
        forkJoin(allPartsObservables)
          .subscribe(partsOfOrgs => {
            let orgIndex = 0;
            partsOfOrgs.forEach(parts => {
              this.logger.info('==============================================================');
              this.logger.info('== Load Parts from ORGANISATION => ' + orgs[orgIndex].orgid);
              this.logger.info('==============================================================');
              this.logger.info('Number of found parts: ' + parts.length);
              this.allParts.set(orgs[orgIndex].orgid, parts);
              let partIndex = 0;
              parts.forEach(part => {
                partIndex++;
                this.logger.info(partIndex + '. part:' + part.partid + ', phcodes:' + JSON.stringify(part.phcodes));
              });
              orgIndex++;
            });
            if (orgIndex === partsOfOrgs.length) {
              // service is ready
              this.serviceIsReady = true;
              this.bSubjectServiceIsReady.next(true);
              return;
            }
          });
      },

      // error handling (get all orgs)
      (error) => {
        //
        // ToDo: Add Splunk Log
        //
        this.logger.error('(module-data.service) Error get all Organisations => ' + error.message);
        this.serviceIsReady = true;
        this.bSubjectServiceIsReady.next(true);
        return;
      }
    );
  }


  public getModuleData(moduleId?: string): Observable<ModuleData> {
    this.logger.info('(module-data.service) Enter => getModuleData(moduleId?: string), moduleId: ' + moduleId);

    return new Observable<ModuleData>((subscriber) => {
      // check if data already available
      if (this.moduleDataSelected) {
        if (moduleId) {
          subscriber.next(this.getModuleOfId(moduleId));
          subscriber.complete();
          return;
        }
        subscriber.next(this.moduleDataSelected);
        subscriber.complete();
        return;
      }

      this.logger.info('(module-data.service) Subscribe => bSubjectServiceIsReady');
      const serviceFlag = this.bSubjectServiceIsReady.subscribe(serviceIsReady => {
        this.logger.info('(module-data.service) bSubjectServiceIsReady, Result => ' + serviceIsReady);
        if (this.serviceIsReady) {
          this.logger.info('(module-data.service) bSubjectServiceIsReady, All Module data is loaded!');
          this.moduleDataSelected = this.getModuleOfId(moduleId ? moduleId : this.parService.calledTypeCode);
          subscriber.next(this.moduleDataSelected);
          subscriber.complete();
        } else {
          this.logger.info('(module-data.service) bSubjectServiceIsReady, Module data - NOT yet loaded!');
        }
      });
      return;
    });
  }

  //
  // Get module with forwarded moduleId (of org)
  //
  public getModuleOfId(id: string, orgid?: string): ModuleData {
    let partData: ModuleData = {
      moduleId: '', cat: '',  phcodes: [], link: '', title: '', description: '', image: ''
    };
    // check orgid
    if (!orgid) {
      orgid = 'DC-AE';
    }
    // get part
    const partsOfOrg = this.allParts.get(orgid);
    if (partsOfOrg) {
      for (const part of partsOfOrg) {
        // use the longest matching string
        if (id.startsWith(part.partid) && (part.partid.length > partData.moduleId.length)) {
          partData = this.createPartData(orgid, part);
        }
      }
    }
    return partData;
  }

  //
  // Get all modules of a category (and org)
  //
  public getModulesOfCategory(category?: string, orgid?: string): ModuleData[] {
    const partsOfCat: ModuleData[] = [];

    // check orgid
    if (!orgid) {
      orgid = 'DC-AE';
    }
    // get parts
    const partsOfOrg = this.allParts.get(orgid);
    if (partsOfOrg) {
      for (const part of partsOfOrg) {
        if (part.cat === category) {
          const part2 = this.createPartData(orgid, part);
          partsOfCat.push(part2);
        }
      }
    }
    return partsOfCat;
  }

  //
  // Convert part data from json file format to PartData format
  //
  private createPartData(orgid: any, part: any) {
    const language = this.parService.language;
    const partData: ModuleData = {
      moduleId: '', cat: '', phcodes: [], link: '', title: '', description: '', image: ''
    };
    partData.moduleId = part.partid;
    partData.cat = part.cat;
    partData.phcodes = part.phcodes;
    partData.link = part.link;
    partData.image = DEFINITIONS.urlGeneratorApi + '/organisations/' + orgid + '/parts/' + part.partid + '/image';

    if (language === DEFINITIONS.deDeLanguage) {
      partData.title = part.lang.de_DE.title;
      partData.description = part.lang.de_DE.descr;
    } else {
      partData.title = part.lang.en_US.title;
      partData.description = part.lang.en_US.descr;
    }
    return partData;
  }
}
