<!--
  ~ Copyright (C) 2019 Robert Bosch GmbH Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
  -->

  <div class="dialog-header">
    <h3 matDialogTitle translate>_HelpHeadline</h3>
    <button class="close-button" mat-icon-button tabindex="0" (click)="clickCancel()">
      <i class="Bosch-Ic Bosch-Ic-abort"></i>
    </button>
  </div>
  <mat-dialog-content>
    <div>
      <h5><b><span translate>_Help1_Headline</span></b></h5>
      <p><span translate>_Help1_Text1</span></p>
    </div>
    <div>
      <h5><b><span translate>_Help2_Headline</span></b></h5>
      <p><span translate>_Help2_Text1</span></p>
      <p><span translate>_Help2_Text2</span></p>
      <div id="helpImageContainer">
          <div id="helpImage"><img [src]="'_Help2_ImgPath1'|translate" [alt]="'_ImgPath'|translate"></div>
          <div id="helpImage"><img [src]="'_Help2_ImgPath2'|translate" [alt]="'_ImgPath'|translate"></div>
      </div>
      <p><span translate>_Help2_Text3</span></p>
      <p><span translate>_Help2_Text4</span></p>
      <div id="helpImageContainer">
        <div id="helpImage"><img [src]="'_Help2_ImgPath3'|translate" [alt]="'_ImgPath'|translate"></div>
      </div>
      <p><span translate>_Help2_Text5</span></p>
      <p><span translate>_Help2_Text6</span><a href="https://developer.community.boschrexroth.com/t5/ctrlX-AUTOMATION-Community/EPLAN-Article-Data-Generator/ba-p/19280" target="_blank"> ctrlX AUTOMATION Community</a>.</p>
    </div>
  </mat-dialog-content>
