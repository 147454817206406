import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { SidebarNavItem, ModalWindowService } from '@bci-web-core/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ModuleData } from './module-data';
import { ParameterService } from './parameter.service';
import { ModuleDataService } from './module-data.service';
import { OperationHelpComponent} from '../operation-help/operation-help.component';

export interface NavigationParams {
  moduleId?: string;           // moduleId: typeCode prefix which identifies module and generator (e.g.: 'S20-')
  downloads?: boolean;         // 'true': provide generation results as downloads, 'false': show EPLAN buttons
  uc?: string;                 // GUI UseCase ('Overview', ....)
  language?: string;           // Language ShortKey: 'de_DE', 'en_US'....
  replaceInHistory?: boolean;  // 'true': do not store route in browser history, 'false': store in browser history
  reloadRoute?: boolean;       // 'true': force angular router to reload route if already loaded, 'false': do not reload
}

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  sidebarLinks: SidebarNavItem[] = [];
  sidebarLinksSubject = new BehaviorSubject<SidebarNavItem[]>([]);
  sidebarFooterLinks: SidebarNavItem[] = [];
  sidebarFooterLinksSubject = new BehaviorSubject<SidebarNavItem[]>([]);
  private _currentOrderId = '0';

  public moduleData: ModuleData;   // used in unittests
  public mainNavigLinks = [];
  public footerNavigLinks = [];
  public currentMenuLanguage: string;

  // sidebar menu item titles
  private navHomeTitle = '_NavHome';
  private navGeneratorTitle = '_NavGenerator';
  private navOrderAdministrationTitle = '_NavOrderAdministration';
  private navOrderTrackerTitle = '_NavOrderTracker';
  private navHelpTitle = '_NavHelp';
  private navOperationHelpTitle = '_NavOperationHelp';
  private navSupportRequestTitle = '_NavSupport';
  private navForumTitle = '_NavForum';
  private navRexrothStoreTitle = '_NavRexrothStore';
  private navPrivacyTitle = '_NavPrivacy';
  private navAboutTitle = '_NavAbout';
  private navImprintTitle = '_NavImprint';

  private i18nSidebarTextKeys = [
    '_NavHome',
    '_NavGenerator',
    '_NavOrderAdministration',
    '_NavOrderTracker',
    '_NavHelp',
    '_NavOperationHelp',
    '_NavSupport',
    '_NavForum',
    '_NavRexrothStore',
    '_NavPrivacy',
    '_NavAbout',
    '_NavImprint' ]


  private queryParams = { rootpartnr: '', downloads: '', uc: '', lang: '' };

  // menu use cases (menu items indices to use for use case)
  private ucOverviewMenus = [0, 1];
  private ucGeneratorMenus = [0, 1];     //map uc generator to uc overview
  private ucOrderTrackingMenus = [0, 1, 2];
  private ucOrderAdministrationMenus = [0, 1, 2, 3];
  private menusOfActiveUseCase: number[] = [];

  public ucOverview = false;             // Menus: Overview - Generator
  public ucGenerator = false;            // Menus: Generator
  public ucOrderTracking = false;        // Menus: Overview - Generator - Order Tracking
  public ucOrderAdministration = false;  // Menus: Overview - Generator - Order Administration - Order Tracking
  public selectedUseCase: string;        // Name of selected use case (overview, generator, tracking, admin)

  //
  // sidebar main NavItems
  //
  // sidebar NavItem 0 => Home
  private navHome: SidebarNavItem = {
    id: 'navHome',
    title: this.navHomeTitle,
    url: 'overview',
    queryParams: undefined,
    position: 0,
    icon: 'Bosch-Ic-home-outline',
    routerLinkActiveOptions: {
      exact: true
    }
  };
  // sidebar NavItem 1 => Generator
  private navGenerator: SidebarNavItem = {
    id: 'navGenerator',
    title: this.navGeneratorTitle,
    url: 'generator',
    queryParams: undefined,
    position: 1,
    icon: 'bosch-ic-settings-arrows',
    routerLinkActiveOptions: {
      exact: true
    }
  };
  // sidebar NavItem 2 => Order Tracker
  private navOrderTracker: SidebarNavItem = {
    id: 'navOrderTracker',
    title: this.navOrderTrackerTitle,
    url: 'orders/' + this._currentOrderId,
    queryParams: undefined,
    position: 2,
    icon: 'bosch-ic-watch-on',
    routerLinkActiveOptions: {
      exact: true
    }
  };
  // sidebar NavItem 3 => Order Adminsitration
  private navOrderAdministration: SidebarNavItem = {
    id: 'navOrderAdministration',
    title: this.navOrderAdministrationTitle,
    url: 'orders/administration',
    queryParams: undefined,
    position: 3,
    icon: 'bosch-ic-core-data',
    routerLinkActiveOptions: {
      exact: true
    }
  };

  //
  // sidebar footer NavItems
  //
  private navHelpOperationHelp: SidebarNavItem = {
    id: 'navOperationHelp',
    cb: () => this.onOperationHelp(),
    title: this.navOperationHelpTitle,
    icon: 'Bosch-Ic-help'
  };
  private navHelpSupportRequest: SidebarNavItem = {
    id: 'navSupportRequest',
    cb: () => this.onSendSupportRequest(),
    title: this.navSupportRequestTitle,
    icon: 'Bosch-Ic-help'
  };
  private navHelpForum: SidebarNavItem = {
    id: 'navForum',
    cb: () => this.onForum(),
    title: this.navForumTitle,
    icon: 'Bosch-Ic-help'
  };
  private navHelpPrivacy: SidebarNavItem = {
    id: 'navPrivacy',
    cb: () => this.onPrivacy(),
    title: this.navPrivacyTitle,
    icon: 'Bosch-Ic-information'
  };
  private navProductCatalogue: SidebarNavItem = {
    id: 'navProductCatalogue',
    cb: () => this.onRexrothStore(),
    title: this.navRexrothStoreTitle,
    icon: 'Bosch-Ic-information'
  };

  private navHelpChildItems: SidebarNavItem[] = [
    this.navHelpOperationHelp,
    this.navHelpSupportRequest,
    this.navHelpForum,
    this.navHelpPrivacy,
  ];

  private navHelp: SidebarNavItem = {
    id: 'navHelp',
    title: this.navHelpTitle,
    url: 'dummy-link',
    icon: 'Bosch-Ic-help',
    items: this.navHelpChildItems
  };

  private navImprint: SidebarNavItem = {
    id: 'navImprint',
    cb: () => this.onImprint(),
    title: this.navImprintTitle,
    icon: 'Bosch-Ic-information'
  };

  private navAbout: SidebarNavItem = {
    id: 'about.menu',
    title: this.navAboutTitle,
    icon: 'Bosch-Ic-information',
    url: 'about',
    queryParams: this.queryParams,
  }


  constructor(private logger: NGXLogger,
              private parService: ParameterService,
              private moduleService: ModuleDataService,
              private translate: TranslateService,
              private modalWindowService: ModalWindowService,
              private router: Router) {

    this.logger.info('(navigation.service) => constructor()');
    translate.use(parService.language);
    translate.addLangs(['en_US', 'de_DE']);
    this.currentMenuLanguage = parService.language;

    //set router reuse strategy
    if (this.router.routeReuseStrategy) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    // load module data
    this.moduleService.getModuleData(this.parService.calledTypeCode).subscribe( (data) => {
      this.moduleData = data;
      this.identifyMenuUseCase();
      this.createNavigationItems({});
    });

    // load texts and create menus items
    this.createAllMenus();
  }

  public identifyMenuUseCase() {

    // return if useCase is already identified
    if (this.ucOverview || this.ucGenerator || this.ucOrderTracking || this.ucOrderAdministration) {
      return;
    }

    // identify use case (via uc query param)
    switch (this.parService.uc) {
      case 'overview':
        this.setUcActive('overview');
        break;
      case 'generator':
        this.setUcActive('generator');
        break;
      case 'tracking':
        this.setUcActive('tracking');
        break;
      case 'admin':
        this.setUcActive('admin');
        break;
      default:
        // check for historical ucs
        if ((this.parService.getWinHref().indexOf(this.parService.getWinOrigin() + '/generator?') === 0) ||
            (this.parService.getWinHref().indexOf(this.parService.getWinOrigin() + '?') === 0)) {

          // use case product catalogue
          if (this.parService.rootPartNr && this.parService.downloads) {
            this.setUcActive('overview');
            return;
          }
          // use case Eplan DataPortal
          if (this.parService.rootPartNr && !this.parService.downloads) {
            this.setUcActive('generator');
            return;
          }
        }
        break;
    }

    // check for call with route /orders/{:orderid}
    if (this.parService.getWinHref().indexOf('/overview') >= 0) {
      if (!this.ucOrderTracking && !this.ucOrderAdministration) {
        this.setUcActive('overview');
      }
      return;
    }

    // check for call with route /generator
    if (this.parService.getWinHref().indexOf('/generator') >= 0) {
      if (!this.ucOrderTracking && !this.ucOrderAdministration) {
        this.setUcActive('generator');
      }
      return;
    }
    
    // check for call with route /orders/administration
    if (this.parService.getWinHref().indexOf('/orders/administration') >= 0) {
      this.setUcActive('admin');
      return;
    }

    // check for call with route /orders/{:orderid}
    if (this.parService.getWinHref().indexOf('/orders/') >= 0) {
      if (!this.ucOrderAdministration) {
        this.setUcActive('tracking');
      }
      return;
    }
  }

  private setUcActive(ucName: string) {
    this.ucOverview = false;
    this.ucGenerator = false;
    this.ucOrderTracking = false;
    this.ucOrderAdministration = false;

    switch (ucName) {
      case 'overview':
        this.menusOfActiveUseCase = this.ucOverviewMenus;
        this.ucOverview = true;
        break;
      case 'generator':
        this.menusOfActiveUseCase = this.ucGeneratorMenus;
        this.ucGenerator = true;
        break;
      case 'tracking':
        this.menusOfActiveUseCase = this.ucOrderTrackingMenus;
        this.ucOrderTracking = true;
        break;
      case 'admin':
        this.menusOfActiveUseCase = this.ucOrderAdministrationMenus;
        this.ucOrderAdministration = true;
        break;
    }
    // save selected uc name
    this.selectedUseCase = ucName;
  }

  public getActiveUc() {
    this.identifyMenuUseCase();
    if( this.ucOverview) {
      return 'overview';
    }
    if( this.ucGenerator) {
      return 'generator';
    }
    if( this.ucOrderTracking) {
      return 'tracking';
    }
    if( this.ucOrderAdministration) {
      return 'admin';
    }
  }


  private createAllMenus() {
    this.translate.get( this.i18nSidebarTextKeys)
      .subscribe(
      data => {
        // set sidbar strings
        this.assignSidebarTextStrings(data)

        // refresh menu items
        this.identifyMenuUseCase();
        this.createNavigationItems({ rootPartNr: this.parService.rootPartNr });
        this.createNavigationFooterItems();

        // update subscribers
        if (this.sidebarLinksSubject) {
          this.sidebarLinksSubject.next(this.sidebarLinks);
        }
        if (this.sidebarFooterLinksSubject) {
          this.sidebarFooterLinksSubject.next(this.sidebarFooterLinks);
        }
      });
  }

  private refreshMenuStrings() {
    this.translate.get(this.i18nSidebarTextKeys)
      .subscribe(
      data => {
        // set sidbar strings
        this.assignSidebarTextStrings(data);

        // update NavItems
        this.updateMainNavItems();
        this.updateFooterNavItems();
      });
  }

  private assignSidebarTextStrings(data) {
    // set sidbar strings
    this.navHomeTitle = data._NavHome;
    this.navGeneratorTitle = data._NavGenerator;
    this.navOrderAdministrationTitle = data._NavOrderAdministration;
    this.navOrderTrackerTitle = data._NavOrderTracker;
    this.navHelpTitle = data._NavHelp;
    this.navOperationHelpTitle = data._NavOperationHelp;
    this.navSupportRequestTitle = data._NavSupport;
    this.navForumTitle = data._NavForum;
    this.navRexrothStoreTitle = data._NavRexrothStore;
    this.navPrivacyTitle = data._NavPrivacy;
    this.navAboutTitle = data._NavAbout;
    this.navImprintTitle = data._NavImprint;
  }

  private createQueryParams(naviParams?: NavigationParams) {
    // rootpartnr
    if (naviParams && naviParams.moduleId !== undefined) {
      if (naviParams.moduleId.startsWith('BOS.')) {
        this.queryParams.rootpartnr = naviParams.moduleId;
      } else {
        this.queryParams.rootpartnr = 'BOS.' + naviParams.moduleId + '**';
      }
    } else {
      this.queryParams.rootpartnr = this.parService.rootPartNr;
    }

    // downloads
    if (naviParams && naviParams.downloads !== undefined) {
      this.queryParams.downloads = naviParams.downloads.toString();
    } else {
      this.queryParams.downloads = this.parService.downloads.toString();
    }

    // usecase (uc)
    if (naviParams && naviParams.uc !== undefined) {
      this.queryParams.uc = naviParams.uc;
    } else {
      this.identifyMenuUseCase();
      this.queryParams.uc = this.selectedUseCase ? this.selectedUseCase : this.parService.uc;
    }

    // language
    if (naviParams && naviParams.language !== undefined) {
      this.queryParams.lang = naviParams.language;
    } else {
      this.queryParams.lang = this.parService.language;
    }
  }

  private createNavigationItems(params?: { rootPartNr?: string, orderId?: string }): void {

    // return if useCase isn't available
    if (!this.ucOverview && !this.ucGenerator && !this.ucOrderTracking && !this.ucOrderAdministration) {
      return;
    }

    // save orderId
    if (params.orderId) {
      this._currentOrderId = params.orderId;
    }

    // create NavigItems
    this.mainNavigLinks = [
      this.navHome,
      this.navGenerator,
      this.navOrderTracker,
      this.navOrderAdministration
    ];

    // create NavigItems (consider use case)
    this.mainNavigLinks = this.getNavigItemsForUseCase();

    // create query params
    this.createQueryParams({ moduleId: params.rootPartNr } );

    // update NavItems
    this.updateMainNavItems();

    // add or delete generator NavItem
    this.handleGeneratorMenuItem(this.mainNavigLinks);

    this.logger.trace('(navigation.service) SidebarNavItems: ' + JSON.stringify(this.mainNavigLinks));
    this.sidebarLinks = this.mainNavigLinks;
    this.sidebarLinksSubject.next(this.sidebarLinks);
  }

  private getNavigItemsForUseCase() {
    const navigLinks = [];
    for (const index of this.menusOfActiveUseCase) {
      switch (index) {
        case 0:
          navigLinks.push(this.navHome);
          break;
        case 1:
          navigLinks.push(this.navGenerator);
          break;
        case 2:
          navigLinks.push(this.navOrderTracker);
          break;
        case 3:
          navigLinks.push(this.navOrderAdministration);
          break;
      }
    }
    return navigLinks;
  }

  private updateMainNavItems() {
      // navHome
    this.navHome.title = this.navHomeTitle;
    this.navHome.queryParams = this.queryParams;
    // navGenerator
    this.navGenerator.title = this.navGeneratorTitle;
    this.navGenerator.queryParams = this.queryParams;
    // navOrderTracker
    this.navOrderTracker.title = this.navOrderTrackerTitle;
    this.navOrderTracker.queryParams = this.queryParams;
    this.navOrderTracker.url = 'orders/' + this._currentOrderId;
    // navOrderAdministration
    this.navOrderAdministration.title = this.navOrderAdministrationTitle;
    this.navOrderAdministration.queryParams = this.queryParams;
  }

  private updateFooterNavItems() {
    // navHelpOperationHelp
    this.navHelpOperationHelp.title = this.navOperationHelpTitle;
    // navHelpSupportRequest
    this.navHelpSupportRequest.title = this.navSupportRequestTitle;
    // navHelpForum
    this.navHelpForum.title = this.navForumTitle;
    // navHelpPrivacy
    this.navHelpPrivacy.title = this.navPrivacyTitle;
    // navProductCatalogue
    this.navProductCatalogue.title = this.navRexrothStoreTitle;
    // navHelp
    this.navHelp.title = this.navHelpTitle;
    // navAbout
    this.navAbout.title = this.navAboutTitle;
    // navImprint
    this.navImprint.title = this.navImprintTitle;
  }

  private handleGeneratorMenuItem(navigItems: SidebarNavItem[], moduleId?: string) {
    // get moduleData
    if (this.moduleService.ModuleDataLoaded === true) {
      if (moduleId) {
        this.moduleData = this.moduleService.getModuleOfId(moduleId);
      } else {
        this.moduleData = this.moduleService.getModuleOfId(this.parService.calledTypeCode);
      }
    }
    // get index of generator NavItem
    let indexNavItemGenerator = -1;
    for (let i = 0; i < navigItems.length; i++) {
      if (navigItems[i].id === 'navGenerator') {
        indexNavItemGenerator = i;
        break;
      }
    }
    // delete or add generator NavItem depending on moduleData availability
    if (!this.moduleData || !this.moduleData.moduleId) {
      if (indexNavItemGenerator > -1) {
        navigItems.splice(indexNavItemGenerator, 1);
      }
    } else {
      if (indexNavItemGenerator === -1) {
        navigItems.splice(1, 0, this.navGenerator);
      }
    }
  }

  private createNavigationFooterItems(): void {
    this.updateFooterNavItems();
    this.footerNavigLinks = [this.navProductCatalogue, this.navHelp, this.navImprint, this.navAbout];
    this.logger.trace('(navigation.service) SidebarNavFooterItems: ' + JSON.stringify(this.footerNavigLinks));
    this.sidebarFooterLinks = this.footerNavigLinks;
  }

  public getNavigationItems(orderId?: string): BehaviorSubject<SidebarNavItem[]> {
    this.createNavigationItems({ rootPartNr: this.parService.rootPartNr, orderId });
    this.sidebarLinksSubject = new BehaviorSubject<SidebarNavItem[]>(this.sidebarLinks);
    return this.sidebarLinksSubject;
  }

  public getNavigationFooterItems(): BehaviorSubject<SidebarNavItem[]> {
    this.createNavigationFooterItems();
    this.sidebarFooterLinksSubject = new BehaviorSubject<SidebarNavItem[]>(this.sidebarFooterLinks);
    return this.sidebarFooterLinksSubject;
  }

  public updateRouteParameters(params?: { rootPartNr?: string, orderId?: string }) {
    // save orderId
    if (params.orderId) {
      this._currentOrderId = params.orderId;
    }

    // update NavItems
    for (const item of this.sidebarLinks) {
      // update orderId
      if (params.orderId && item.id === 'navOrderTracker') {
        item.url = 'orders/' + params.orderId;
      }
      // update rootpartnr
      if (params.rootPartNr) {
        item.queryParams.rootpartnr = params.rootPartNr;
      }
    }
  }

  //
  // Initialize and set query params in url
  //
  public async initQueryParams() {
    // create query parameters
    this.createQueryParams(undefined);

    // set routeReuseStrategy
    if (this.router.routeReuseStrategy) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => true;
    }

    // set query params in url
    await this.router.navigate([],
    {
      queryParams: this.queryParams,
      replaceUrl: true
    });

    // restore routeReuseStrategy
    if (this.router.routeReuseStrategy) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
  }

  public async navigate(route: string, naviParams?: NavigationParams) {
    const replaceUrlInHistory = (naviParams && naviParams.replaceInHistory !== undefined) ? naviParams.replaceInHistory : false;

    // create query parameters
    this.createQueryParams(naviParams);

    // route ordertracking: update url of navigItem
    if (!route.startsWith('orders/administration')) {
      if (route.startsWith('orders/') && route.length > 7) {
        for (const item of this.sidebarLinks) {
          if (item.id === 'navOrderTracker') {
            item.url = route;
          }
        }
      }
    }

    // add or delete generator NavItem
    const moduleId = (naviParams && naviParams.moduleId && naviParams.moduleId !== '') ? naviParams.moduleId : undefined;
    this.handleGeneratorMenuItem(this.sidebarLinks, moduleId);
    this.sidebarLinksSubject.next(this.sidebarLinks);

    // allow route reloading for 'generator' route
    this.logger.info('(navigation.service) Change Route, naviParams => ' + JSON.stringify(naviParams));

    // configure routeReuseStrategy
    if (naviParams && naviParams.reloadRoute !== undefined && naviParams.reloadRoute === false) {
      if (this.router.routeReuseStrategy) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => true;
      }
    }

    // navigate to route, hide 'orders' query param if not provided
    this.logger.info('(navigation.service) Change Route, QueryParams => ' + JSON.stringify(this.queryParams));

    // do navigation
    await this.router.navigate([route],
    {
      queryParams: this.queryParams,
      replaceUrl: replaceUrlInHistory
    });

    // restore routeReuseStrategy
    if (this.router.routeReuseStrategy) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    // refresh for menu items for language change
    if (this.currentMenuLanguage !== this.queryParams.lang) {
      this.translate.use(this.parService.language);
      this.currentMenuLanguage = this.parService.language;
      this.refreshMenuStrings();
    }
  }

  public get currentOrderId(): string {
    return this._currentOrderId;
  }

  private onOperationHelp() {
    this.modalWindowService.openDialogWithComponent(OperationHelpComponent, { width: '1100px' });
  }

  private onSendSupportRequest() {
    window.open('mailto:ECAD-Configuration.Support@boschrexroth.de?subject=' +
                '[ECAD-Configuration] Support Request (Typecode: ' + this.parService.calledTypeCode + ')', '_blank');
  }

  private onForum() {
    window.open("https://developer.community.boschrexroth.com/t5/ctrlX-SERVICES/bd-p/dcdev_community-dcae-configurator");
  }

  private onRexrothStore() {
    window.open("https://www.boschrexroth.com/product/cls_eCommerce_lx_279235");
  }

  private onTermsOfUse() {
    this.translate.get('_TermsOfUseFileName').subscribe(data => window.open(data, '_blank'));
  }

  private onPrivacy() {
    this.translate.get('_PrivacyLink').subscribe(data => window.open(data, '_blank'));
  }

  private onImprint() {
    this.translate.get('_ImprintLink').subscribe(data => window.open(data, '_blank'));
  }
}
