import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { TranslateService} from '@ngx-translate/core';
import { DEFINITIONS } from '../definitions';

@Injectable({
  providedIn: 'root',
})

// ParameterService: Provides acces to the url query parameters
// Query parameters for Rexroth generator are:
// 1.) rootpartnr:
//     Mandatory parameter. Type code of the eplan root parameter
//     (e.g. BOS.CSB01.B-** or a full type code BOS.CSB02.1A-ET-EC-PB-L3-NN-NN-FW)
// 2.) downloads
//     Optional parameter. If 'true' generator provide results via download links,
//     if 'false' via eplan buttons 'Import' and 'Place Makro'. The default value
//     is false.
// 3.) uc
//     Optional parameter. Specifies the use case which is responsible for sidebar menu items
//     the following use case are available: overview / generator / tracking / admin
// 4.) lang
//     Optional parameter. Allowed values de_DE or en_US. Default value is browser
//     language. If browser language isn't de_DE or en_US langauge is setted to en_US.
export class ParameterService {
  private readonly deDeLanguage = DEFINITIONS.deDeLanguage;
  private readonly defaultLanguage = DEFINITIONS.defaultLanguage;
  private readonly browserLanguage: string;

  constructor(private logger: NGXLogger,
              private translate: TranslateService) {
    this.logger.info('(parameter.service) => constructor()');
    this.browserLanguage = translate.getBrowserLang();
  }

  // Provides url query paramter 'rootpartnr'
  public get rootPartNr(): string {
    return this.getParam('rootpartnr');
  }

  // Provides url query paramter 'rootpartnr' without prefix 'BOS.'
  public get calledTypeCode(): string {
    if ((this.rootPartNr.indexOf('BOS.') !== 0) || (!this.rootPartNr.endsWith('**'))) {
      return '';
    }
    return this.rootPartNr.substring(4, this.rootPartNr.length - 2);
  }

  // Provides url query paramter 'downloads'
  public get downloads(): boolean {
    const downloads = this.getParam('downloads');
    return downloads === 'false' ? false : true;
  }

  // Provides url query paramter 'lang'
  public get language(): string {
    const lang = this.getParam('lang');
    if (lang === this.deDeLanguage) {
      return lang;
    }
    if (lang === this.defaultLanguage) {
      return lang;
    }
    return this.browserLanguage.match(/de/) ? this.deDeLanguage : this.defaultLanguage;
  }

  // Provides url query paramter 'uc' (values could be: overview, generator, tracking, admin)
  public get uc(): string {
    return this.getParam('uc');
  }

  // Reads url query paramter with provided 'name'
  public getParam(name: string): string {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodeURIComponent(this.getWinHref()));
    if (!results) {
      return '';
    }
    return results[1] || '';
  }

  // Helper function to allow unittest mocking of window.location.href
  public getWinHref(): string {
    return window.location.href;
  }

  // Helper function to allow unittest mocking of window.location.origin
  public getWinOrigin(): string {
    return window.location.origin;
  }

}
