import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-operation-help',
  templateUrl: './operation-help.component.html',
  styleUrls: ['./operation-help.component.scss']
})
export class OperationHelpComponent implements OnInit {

  constructor(public translate: TranslateService,
              private dialogRef: MatDialogRef<OperationHelpComponent>) { }

  ngOnInit() {
  }

  public clickCancel(): void {
    this.dialogRef.close();
  }
}
