export const DEFINITIONS = {
  serverUrlStatus: '/api/jobs/',
  
  sessParEcadUser: 'ecadUser',
  sessParEcadUserLoggedIn: 'ecadUserLoggedIn',

  serverUrlOrders: '/orders/api/v2/orders',
  serverUrlUsersAuthenticate: '/orders/api/v1/users/authenticate',
  serverUrlGeneratorLogin: '/generator/login',
  serverUrlGeneratorLogout: '/generator/logout',
  
  serverUrlDownload: '/downloads',
  serverUrlTestsystem: 'https://ecad-configuration-dev.apps.de1.bosch-iot-cloud.com',
  sendMethodCreate: 'PUT',
  sendMethodStatus: 'GET',
  pollingInterval: 500,

  urlGeneratorApi: '/generator/api/v1',
  defaultOrgId: 'DC-AE',

  deDeLanguage: 'de_DE',
  defaultLanguage: 'en_US',
  edzZipFilePrefix: 'EplanFiles_Edz_',
  pdfZipFilePrefix: 'EplanFiles_Pdf_',

  // generator running mode
  sessParGenMode: 'ecad.generator.mode',
  sessParEplanVersion: 'eplan.version',
  modeEmbedded: 'embedded',
  modeBrowserEmbedded: 'browser-embedded',
  modeEplanEmbedded: 'eplan-embedded',
  modeStandalone: 'standalone',
  
  // typeCode states
  jobState_Queued: 'Queued',
  jobState_Running: 'Running',
  jobState_Successful: 'FinishedSuccessfully',
  jobState_Error: 'FinishedInError',
  jobState_Error_TypeCode: 'FinishedInErrorTypeCode',

  // order states
  orderStateStopped : 'stopped',
  orderStateOpen : 'open',
  orderStateInWork : 'inWork',
  orderStateSuccessful : 'finished.success',
  orderStateError : 'finished.error',

  // type code states
  typeCodeStateOpen : 'open',
  typeCodeStateInWork : 'inWork',
  typeCodeStateSuccess: 'finished.success',
  typeCodeStateError: 'finished.error',
  typeCodeStateErrorTypeCode: 'finished.error.typecode',

  // type code validation
  regexTypeCode: /^([\w\d\.\(\)\,\-\/\ \*\+]*)$/,    //check: typecode and allowed special chars
  regexBosTypeCode: /^(?:BOS\.).+(?:\*\*)$/          //check: only format in url which is "BOS.<any chars>**"
  //regexBosFullTypeCode: /^(?:BOS\.)([\w\d\.\(\)\,\-\/\ \*\+]*)(?:\*\*)$/   //check: full typecode string in url
};
